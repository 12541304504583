import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '450'
  },
  xl: {
    height: '500'
  }
}

const TaxExemptionPlanHelp = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "vers-quel-dispositif-de-defiscalisation-me-tourner.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
  <ImageSection
    size={size}
    right={false}
    imageData={placeholderImage}
    backgroundColor='paleBlue'>
    <HomeSectionTitle tag='h2'>
      Vers quel dispositif de défiscalisation me tourner ?
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Le choix du dispositif de défiscalisation dépend tout simplement du profil de l’investisseur.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Pour faire un choix avisé, il est donc vivement recommandé de s’informer précisément sur les conditions d’admissibilité. En revanche, les dispositifs pour inciter à investir dans l’immobilier neuf touchent une plus grande partie de la population. D’autant plus que ces dispositifs, notamment Pinel, peuvent être cumulés avec les différents prêts d’aide à l’acquisition, comme le PTZ, par exemple.
    </HomeSectionDescription>
  </ImageSection>
  )
}

export default TaxExemptionPlanHelp
