import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import Header from '../../atoms/HeroHeader'
import Subtitle from '../../atoms/HeroSubtitle'
import Container from '../../atoms/HeroContainer'
import Description from '../../atoms/HeroDescription'

const StyledContainer = styled(Container)`
  max-width: 650px;
  margin: auto;
  height: 700px;
  
  ${media.greaterThan('md')`
    padding: 0;
  `}
  
  ${media.greaterThan('xl')`
    max-width: 500px;
    margin-right: 55%;
  `}

  ${media.greaterThan('xxl')`
    max-width: 580px;
    margin-right: 50%;
  `}
`

const TaxExemptionPlan = () => (
    <StyledContainer>
      <Header max={100}>
        Les dispositifs de défiscalisation dans l’immobilier
      </Header>
      <Subtitle>
        Vous cherchez à investir dans l’immobilier ? Sachez que vous pouvez peut-être bénéficier d’un des dispositifs de défiscalisation en vigueur. Afin d’encourager de plus en plus de personnes à investir, l’État a mis en place des avantages fiscaux.
      </Subtitle>
      <Description>
        La plupart de ces avantages permettent de capitaliser tout en réduisant le montant de ses impôts. Aujourd’hui, trois principaux dispositifs existent : Pinel, Censi-Bouvard et la location LMNP. En outre, d’autres dispositifs moins connus, car plus particuliers, permettent également de bénéficier d’avantages fiscaux, à savoir les lois Malraux et Monuments Historiques. Folhomee revient <strong>sur tous les dispositifs de défiscalisation immobilière.</strong>
      </Description>
    </StyledContainer>
)

export default TaxExemptionPlan