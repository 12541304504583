import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const TaxExemptionPlanDetails = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "les-principaux-dispositifs-de-defiscalisation.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
  <ImageSection
    fixed={true}
    right={false}
    imageData={placeholderImage}
    backgroundColor='white'>
    <HomeSectionTitle tag='h2'>
      Quels sont les principaux dispositifs de défiscalisation en vigueur ?
    </HomeSectionTitle>
    <HomeSectionSubtitle>
    Aujourd’hui, de nombreux dispositifs permettent de défiscaliser dans le cadre d’un investissement immobilier. Les 3 principaux sont la loi Pinel, l’investissement Censi-Bouvard et le statut LMNP.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
    Chaque dispositif demande de respecter certaines conditions, chacun correspond donc à un type d’investisseur. Quoi qu’il en soit, ces dispositifs permettent de bénéficier d’une réduction d’impôts.
    </HomeSectionDescription>
  </ImageSection>
  )
}

export default TaxExemptionPlanDetails
