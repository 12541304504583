import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '500'
  }
}

const TaxExemptionPlanOld = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "d’autres-dispositifs-de-defiscalisation.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      right={false}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
      <HomeSectionTitle tag='h2'>
        D’autres dispositifs de défiscalisation dans l’immobilier ancien
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Deux autres dispositifs de défiscalisation viennent compléter la liste : les lois Malraux et Monuments Historiques.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Ces lois permettent d’investir dans de l’immobilier ancien en bénéficiant d’avantages fiscaux relativement intéressants. D’un côté, la défiscalisation est basée sur les travaux effectués sur un bien donné, de l’autre, les avantages fiscaux concernent exclusivement les biens immobiliers classés Monuments Historiques.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default TaxExemptionPlanOld
