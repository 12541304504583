import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import PictoColContainer from '../../molecules/PictoColContainer'
import PictoPiggyLarge from '../../../assets/piggy-large.inline.svg'

const DETAILS_COL = [{
  icon: PictoPiggyLarge,
  description: 'Au niveau des avantages fiscaux, les bénéficiaires de Censi-Bouvard peuvent <strong>réduire leur impôt jusqu’à 33&nbsp;000&nbsp;€ sur 9 ans,</strong> soit jusqu’à 11% de la valeur du bien, et ce avec une récupération de la TVA de 20%.'
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const TaxExemptionPlanCensiDetails = () => (
  <Container>
    <HomeSectionTitle tag='h3'>
      Les conditions d'un investissement Censi-Bouvard
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Pour pouvoir profiter de la défiscalisation, les loueurs doivent proposer leur bien à la location pendant minimum 9 ans. De plus, le prix du logement ne doit pas dépasser les 300 000 €, afin de pouvoir optimiser le montant à défiscaliser.
    </HomeSectionSubtitle>
    <PictoColContainer details={DETAILS_COL}/>
    <HomeSectionDescription>
      <br />
      Tout comme le dispositif Pinel, la loi Censi-Bouvard peut concerner un grand nombre d’investisseurs et leur permettre de profiter d’une défiscalisation non négligeable.
    </HomeSectionDescription>
  </Container>
)

export default TaxExemptionPlanCensiDetails
