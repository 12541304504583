import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import TopSection from '../../molecules/TopSection'
import DispositifDefiscalisation from '../../molecules/TaxExemptionPlan'
import DispositifDefiscalisationImage from '../../molecules/TaxExemptionPlanImage'

const StyledDispositifDefiscalisationImage = styled(DispositifDefiscalisationImage)`
  z-index: -1;
  display: none;

  ${media.greaterThan('xl')`
    display: block;
    height: 700px;
    top: 245px;
  `}
`

const TaxExemptionPlanTopSection = () => (
  <TopSection component={StyledDispositifDefiscalisationImage}>
    <DispositifDefiscalisation />
  </TopSection>
)

export default TaxExemptionPlanTopSection