import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '400'
  },
  xl: {
    height: '500'
  }
}

const TaxExemptionPlanPinel = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "loi-pinel.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
  <ImageSection
    size={size}
    right={true}
    imageData={placeholderImage}
    backgroundColor='paleGrey'
    maxText='400'>
    <HomeSectionTitle tag='h2'>
      Loi Pinel :
    </HomeSectionTitle>
    <HomeSectionTitle tag='h2'>
      Profiter de la défiscalisation en investissant dans le neuf
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Le dispositif de défiscalisation le plus connu dans le domaine de l’immobilier est la loi Pinel.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Ce dispositif permet aux investisseurs d’acheter un bien immobilier neuf dans le but de le louer pour obtenir des avantages fiscaux.
    </HomeSectionDescription>
  </ImageSection>
  )
}

export default TaxExemptionPlanPinel
