import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const FolhomeeContactUs = () => (
  <Container>
    <HomeSectionTitle tag='h3'>
      Folhomee se tient à votre disposition
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Nous sommes la pour nous conseiller et vous informer sur les différents dispositifs, ainsi que sur des biens qui peuvent correspondre à votre recherche d'investissement.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Quoi qu’il en soit, les dispositifs de défiscalisation sont nombreux et permettent tous de récupérer une partie du prix du bien.
    </HomeSectionDescription>
    <ButtonContainer>
      <LaunchButton
        link='/nous-contacter'
        title='Nous contacter'
        background='brightOrange' />
    </ButtonContainer>
  </Container>
)

export default FolhomeeContactUs
