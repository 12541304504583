import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import media from '../../../utils/media'

const HistoryImage = ({ historyId, ...props }) => {
  const data = useStaticQuery(graphql`{
    algoImage: file(relativePath: {eq: "history/algo.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    easyImage: file(relativePath: {eq: "history/easy.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    newImage: file(relativePath: {eq: "history/new.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    fastImage: file(relativePath: {eq: "history/fast.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    criteriaImage: file(relativePath: {eq: "history/criteria.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    surfaceImage: file(relativePath: {eq: "history/surface.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    priceImage: file(relativePath: {eq: "history/price.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    localisationImage: file(relativePath: {eq: "history/localisation.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    shopImage: file(relativePath: {eq: "history/shop.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    fiscalImage: file(relativePath: {eq: "history/fiscal.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    piggyLargeImage: file(relativePath: {eq: "history/piggyLarge.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    isolationWaterImage: file(relativePath: {eq: "history/isolationWater.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    energyImage: file(relativePath: {eq: "history/energy.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    new2Image: file(relativePath: {eq: "history/new2.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    isolationWaterImage: file(relativePath: {eq: "history/isolationWater.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    energyImage: file(relativePath: {eq: "history/energy.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    new2Image: file(relativePath: {eq: "history/new2.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    piggyImage: file(relativePath: {eq: "history/piggy.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    keysImage: file(relativePath: {eq: "history/keys.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }
  `)

  return (
    <GatsbyImage
      alt={`Folhomee aggrégateur immobilier, ${historyId}`}
      image={get(data, `${historyId}Image.childImageSharp.gatsbyImageData`)}
      {...props} />
  )
}

HistoryImage.propTypes = {
  size: PropTypes.number,
  historyId: PropTypes.string.isRequired
}

HistoryImage.defaultProps = {
  size: 100
}

const StyledImage = styled(HistoryImage)`
  width: ${(props) => get(props, 'size', 80)}px;
  height: ${(props) => get(props, 'size', 80)}px;

  ${media.lessThan('sm')`
    width: ${(props) => get(props, 'size', 100) * 0.6}px;
    height: ${(props) => get(props, 'size', 100) * 0.6}px;
  `}
`

export default StyledImage
