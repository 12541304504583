import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const TaxExemptionPlanLawMonumentAdvantages = () => {
  const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

  return (
    <Container>
      <HomeSectionTitle tag='h3'>
        Une défiscalisation à 100%
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Avec la loi Monuments Historiques, le coût des travaux et les intérêts d'emprunt sont déductibles à 100% des revenus imposables.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Par ailleurs, le déficit généré est également déductible du revenu global, sans limites.
      </HomeSectionDescription>
      <HomeSectionDescription>
        Ce dispositif est donc particulièrement intéréssant puisqu'il permet de défiscaliser à 100%.
      </HomeSectionDescription>
      <HomeSectionDescription>
        Néanmoins, les investissements de ce type demeurent relativement rares, et ne sont pas forcément à la portée de tous.
      </HomeSectionDescription>
  </Container>
  )
}

export default TaxExemptionPlanLawMonumentAdvantages