import React from 'react'
import styled from 'styled-components'
import { map } from 'lodash'

import media from '../../../utils/media'
import HistoryImage from '../../molecules/HistoryImage'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items : center;
  
  ${media.lessThan('sm')`
    flex-direction: column;
  `}
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;

  & div.gatsby-image-wrapper {
    width: 60px;
    height: 60px;

    ${media.greaterThan('sm')`
      width: 80px;
      height: 80px;
    `}
  }

  ${media.greaterThan('sm')`
    margin-right: 16px;
  `}
`
const StyledTitle = styled(HomeSectionSubtitle)`
  margin-top: 0;
  margin-bottom: 4px;
  
  & b {
    font-weight: 600;
  }

  ${media.lessThan('sm')`
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
  `}
`

const StyledText = styled(HomeSectionDescription)`
  ${media.lessThan('sm')`
    text-align: center;
  `}

  & b {
    font-weight: 600;
  }
`

const Details = ({ data }) => map(data, ({ image, title, description, size }) => (
  <DetailsContainer key={title}>
    <ImageContainer>
      <HistoryImage
        alt={title}
        size={size}
        historyId={image} />
    </ImageContainer>
    <div>
      <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
      <StyledText dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  </DetailsContainer>
))

export default Details
