import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../components/atoms/Seo'
import JsonLD from '../components/atoms/JsonLD'
import Navbar from '../components/organisms/Navbar'
import Footer from '../components/organisms/NewFooter'
import SiteSection from '../components/atoms/SiteSection'
import FolhomeeContactUs from '../components/organisms/FolhomeeContactUs'
import TaxExemptionPlanOld from '../components/organisms/TaxExemptionPlanOld'
import TaxExemptionPlanHelp from '../components/organisms/TaxExemptionPlanHelp'
import TaxExemptionPlanLMNP from '../components/organisms/TaxExemptionPlanLMNP'
import TaxExemptionPlanPinel from '../components/organisms/TaxExemptionPlanPinel'
import TaxExemptionPlanMalraux from '../components/organisms/TaxExemptionPlanMalraux'
import TaxExemptionPlanDetails from '../components/organisms/TaxExemptionPlanDetails'
import TaxExemptionPlanTopSection from '../components/organisms/TaxExemptionPlanTopSection'
import TaxExemptionPlanLawMonument from '../components/organisms/TaxExemptionPlanLawMonument'
import TaxExemptionPlanCensiBouvard from '../components/organisms/TaxExemptionPlanCensiBouvard'
import TaxExemptionPlanCensiDetails from '../components/organisms/TaxExemptionPlanCensiDetails'
import TaxExemptionPlanPinelDetails from '../components/organisms/TaxExemptionPlanPinelDetails'
import TaxExemptionPlanLawMonumentAdvantages from '../components/organisms/TaxExemptionPlanLawMonumentAdvantages'
import { useSiteMetadata } from '../utils/seoTools'
import { deepReplace, replaceGraphKey } from '../utils/deepReplace'

const TITLE = 'Les dispositifs de défiscalisation dans l’immobilier'
const DESCRIPTION = 'Folhomee revient sur les différents dispositifs de défiscalisation dans le cadre d\'un achat immobilier.'

const DispositifDefiscalisation = ({ location }) => {
  const site = useSiteMetadata()
  const { webpage, organization } = deepReplace(site, replaceGraphKey)
  const linkedData = {
    '@context': 'http://schema.org',
    '@type': 'Project',
    name: 'Folhomee',
    description: DESCRIPTION,
    parentOrganization: {
      '@type': 'Organization',
      name: 'Folhomee',
      location: {
        '@type': 'PostalAddress',
        streetAddress: '44 Rue du Chemin Vert',
        addressLocality: 'Paris',
        postalCode: '75011'
      }
    },
    keywords: 'Programme immobilier, Folhomee, page département, recherche immobilier, accompagnement'
  }

  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION}>
        <JsonLD>
          {([webpage, organization, linkedData])}
        </JsonLD>
      </SEO>
      <Navbar location={location} />
      <TaxExemptionPlanTopSection />
      <SiteSection id='tax-exemption-plan-details'>
        <TaxExemptionPlanDetails />
      </SiteSection>
      <SiteSection id='tax-exemption-plan-pinel'>
        <TaxExemptionPlanPinel />
      </SiteSection>
      <SiteSection id='tax-exemption-plan-pinel-details'>
        <TaxExemptionPlanPinelDetails />
      </SiteSection>
      <SiteSection id='tax-exemption-plan-lmnp'>
        <TaxExemptionPlanLMNP />
      </SiteSection>
      <SiteSection id='tax-exemption-plan-censi-bouvard'>
        <TaxExemptionPlanCensiBouvard />
      </SiteSection>
      <SiteSection id='tax-exemption-plan-censi-details'>
        <TaxExemptionPlanCensiDetails />
      </SiteSection>
      <SiteSection id='tax-exemption-plan-old'>
        <TaxExemptionPlanOld />
      </SiteSection>
      <SiteSection id='tax-exemption-plan-malraux'>
        <TaxExemptionPlanMalraux />
      </SiteSection>
      <SiteSection id='tax-exemption-plan-law-monument'>
        <TaxExemptionPlanLawMonument />
      </SiteSection>
      <SiteSection id='tax-exemption-plan-law-advantages'>
        <TaxExemptionPlanLawMonumentAdvantages />
      </SiteSection>
      <SiteSection id='tax-exemption-plan-help'>
        <TaxExemptionPlanHelp />
      </SiteSection>
      <SiteSection id='investissement-autres'>
        <FolhomeeContactUs />
      </SiteSection>
      <Footer noImage={true} noForm={true} />
    </>
  )
}

DispositifDefiscalisation.propTypes = {
  location: PropTypes.object.isRequired
}

export default DispositifDefiscalisation
