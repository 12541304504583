import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import PictoWork from '../../../assets/work.inline.svg'
import StyledList from '../../atoms/StyledList'
import PictoExpert from '../../../assets/expert.inline.svg'
import PictoPriceHouse from '../../../assets/price-house.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoLineContainer from '../../molecules/PictoLineContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import PictoFurnishedRental from '../../../assets/furnished-rental.inline.svg'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_LINE = [{
  icon: PictoWork,
  title: 'Restauration',
  description:'de l’ensemble du bâtiment',
}, {
  icon: PictoExpert,
  title: 'Demande d’autorisation',
  description:'auprès du Préfet pour les travaux',
}, {
  icon: PictoFurnishedRental,
  title: 'Location de logements',
  description:'à titre de résidence principale pendant minimum 9&nbsp;ans',
}, {
  icon: PictoPriceHouse,
  title: 'Mise en location du bien',
  description:'dans l’année suivant la date de fin des travaux',
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const TaxExemptionPlanMalraux = () => (
  <Container>
    <HomeSectionTitle>
      Loi Malraux : la défiscalisation par les travaux
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      La loi Malraux est un dispositif créé en 1962 et vise particulièrement la restauration de bâtiments anciens à caractère historique. Ce dispositif permet de bénéficier d’une réduction d’impôts allant jusqu’à 30% des dépenses entreprises pour les travaux.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      La défiscalisation grâce au dispositif Malraux dépend de la zone géographique du bien immobilier à rénover :
    </HomeSectionDescription>
    <StyledList>
      <li>
        22% pour les bâtiments situés en Site Patrimonial Remarquable (SPR) ou en Aire de Mise en Valeur de l’Architecture et du Patrimoine (AVAP)
      </li>
      <li>
        jusqu’à 30% pour les immeubles en SPR situés dans le périmètre d’un plan de sauvegarde et de mise en valeur, dans un secteur sauvegardé ou dans un quartier ancien dégradé avec une forte concentration de bâtiments anciens dégradés.
      </li>
    </StyledList>
    <HomeSectionDescription>
      Le dispositif Malraux est très intéressant, mais demande de respecter un cahier des charges très strict pour pouvoir en bénéficier :
    </HomeSectionDescription>
    <PictoLineContainer details={DETAILS_LINE}/>
  </Container>
)

export default TaxExemptionPlanMalraux
