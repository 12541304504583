import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import media from '../../../utils/media'
import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const StyledHomeSectionDescription = styled(HomeSectionDescription)`
  ${media.greaterThan('xl')`
    padding-right: 48px;
  `}
`

const size = {
  lg: {
    height: '500'
  }
}

const TaxExemptionPlanLawMonument = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "loi-monuments-historiques.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      right={true}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
      <HomeSectionTitle tag='h2'>
        Loi Monuments Historiques
      </HomeSectionTitle>
      <HomeSectionTitle tag='h3'>
        Rénovation de monuments historiques pour défiscaliser
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Les propriétaires de biens classés Monuments Historiques peuvent bénéficier d’une défiscalisation lorsqu’ils décident de rénover ces bâtiments.
      </HomeSectionSubtitle>
      <StyledHomeSectionDescription>
        Contrairement au dispositif Malraux, la loi Monuments Historiques ne demande pas de louer le bien en question, et les bénéficiaires ne sont pas soumis à un plafond de dépenses pour les rénovations. En revanche, pour pouvoir profiter de la défiscalisation, les propriétaires doivent conserver leur bien pendant au moins 15&nbsp;ans.
      </StyledHomeSectionDescription>
  </ImageSection>
  )
}

export default TaxExemptionPlanLawMonument
