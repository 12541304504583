import React from 'react'
import styled from 'styled-components'
import { map, get } from 'lodash'

import media from '../../../utils/media'
import TaskPaper from '../../../assets/task-paper.inline.svg'
import Calculator from '../../../assets/calculator.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import PictoLineContainer from '../../molecules/PictoLineContainer'

const DETAILS_LINE = [{
  icon: TaskPaper,
  title: 'Le régime micro-foncier ou micro-BIC',
  description: 'qui permet d’obtenir un abattement forfaitaire de 30 ou 50% sur les revenus locatifs'
}, {
  icon: Calculator,
  title: 'Le régime réel',
  description: 'qui permet de déduire les charges du propriétaire (emprunt, travaux, charges, etc) des recettes encaissées.'
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const TaxExemptionPlanLMNP = () => (
  <Container>
    <HomeSectionTitle>
      Statut LMNP : déduction du déficit foncier
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Les loueurs en meublé non professionnel, ou LMNP, peuvent déduire leur déficit foncier ainsi que les charges liées à leur bien. De plus, le statut LMNP permet potentiellement de bénéficier d’une exonération de plus-value.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Dans le cadre du statut LMNP, les loueurs peuvent déduire la totalité de leurs charges foncières du revenu global. Pour rappel, le statut LMNP implique de choisir parmi deux types de régimes&nbsp;:
    </HomeSectionDescription>
    <PictoLineContainer details={DETAILS_LINE}/>
    <HomeSectionDescription>
      En optant pour le régime réel, les charges liées aux murs et au mobilier peuvent faire l’objet d’amortissements.
      <br />
      Pour pouvoir bénéficier du statut LMNP, les revenus locatifs annuels ne doivent pas dépasser les 23&nbsp;000&nbsp;€.
    </HomeSectionDescription>
  </Container>
)

export default TaxExemptionPlanLMNP
