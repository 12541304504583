import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  xl: {
    height: '500'
  }
}

const TaxExemptionPlanCensiBouvard = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "censi-bouvard.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      right={true}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
      <HomeSectionTitle tag='h2'>
        Censi-Bouvard :
      </HomeSectionTitle>
      <HomeSectionTitle tag='h2'>
        Défiscalisez grâce à la location meublée en résidence service
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Le dispositif Censi-Bouvard est également très intéressant du point de vue de la défiscalisation. En réalité, il s’agit d’un complément du statut LMNP.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Les bénéficiaires de Censi-Bouvard obtiennent une réduction d’impôt et une récupération de la TVA grâce à un logement neuf meublé, situé en résidence étudiante ou en résidence pour séniors, par exemple.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default TaxExemptionPlanCensiBouvard
