import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { StaticImage } from 'gatsby-plugin-image'

import media from '../../../utils/media'
import StyledList from '../../atoms/StyledList'
import HomeDetails from '../../molecules/HomeDetails'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import { Link } from 'gatsby'

const DATA = [{
  size: 70,
  image: 'easy',
  title: 'Concrètement, le dispositif Pinel permet de défiscaliser jusqu\'à 63&nbsp;000€'
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 830px;
  padding: 0 24px;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const TextContainer = styled.div`
  display: flex;

  ${media.lessThan('md')`
    flex-direction: column;
  `}
`

const TextContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const OtherList = styled(StyledList)`
  margin-top: 0;
`

const ContainerDetails = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  margin: 32px auto 0;
  max-width: 90%;

  ${media.greaterThan('sm')`
    gap: 32px;
    margin: 40px auto 0;
    max-width: fit-content;
  `}
`

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
`
const StyledButtonContainer = styled(ButtonContainer)`
  width: 100%;
`
const ButtonLink = styled.a`
  width:100%;
  text-decoration: none;
`

const AdButton = styled.div`
  font-family: 'Source Sans Pro';
  color: ${({ theme }) => get(theme, 'lightOrange', '#FFF')};
  padding: 8px;
  border-radius: 27px;
  border: solid 2px ${({ theme }) => get(theme, 'lightOrange', '#FFF')};
  margin: auto;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  max-width: 280px;

  ${media.lessThan('sm')`
    font-size: 16px;
  `}
`

const TaxExemptionPlanPinelDetails = () => (
  <Container>
    <HomeSectionTitle>
      Les conditions d'un investissement Pinel 
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Pour pouvoir profiter de ce dispositif, les bénéficiaires doivent remplir un certain nombre de conditions, notamment liées au bien concerné&nbsp;:
    </HomeSectionSubtitle>
    <TextContainer>
      <TextContent>
        <OtherList>
          <li>
            les loyers doivent être plafonnés, et le bien doit être localisé dans l’une des zones prévues dans le cadre du dispositif Pinel
          </li>
          <li>
            le bien que vous convoitez doit donc être situé dans les zones A, A bis ou B1
          </li>
          <li>
            la location du bien doit se faire avec un contrat d’une durée minimale de 6&nbsp;ans
          </li>
        </OtherList>
        <HomeSectionDescription>
          La location peut également s’étaler sur 9 ou 12&nbsp;ans. Selon la durée de la location du bien, les bénéficiaires défiscalisent à hauteur de 21% du prix de l’investissement, pour un prix d’achat de 300&nbsp;000&nbsp;€ maximum. Au-delà de cette somme, la loi Pinel ne permet pas de défiscaliser.
        </HomeSectionDescription>
        <ContainerDetails>
          <HomeDetails data={DATA} />
        </ContainerDetails>
      </TextContent>
      <ImageContainer>
        <StaticImage
          src='../../../images/les-conditions-d’un-investissement-pinel.png'
          alt='Les plafonds de prix pour l’allègement de la TVA '
          height='320'
          fadeIn={false} 
          loading='eager' />
        <StyledButtonContainer>
          <ButtonLink href='/services/investissement-pinel'>
            <AdButton>&rarr; Voir le détail des zones Pinel</AdButton>
          </ButtonLink>
        </StyledButtonContainer>
      </ImageContainer>
    </TextContainer>
  </Container>
)

export default TaxExemptionPlanPinelDetails
